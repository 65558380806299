<template>
  <div class="oci-check-in-out-body">
    <div v-for="st in sitesList">
      <b-card class="oci-check-in-out-card">
        <b-row>
          <b-col cols="10">
            <b-row>
              <b-col cols="4">
                Job:
              </b-col>
              <b-col>
                {{ st.jobNumber }}
              </b-col>             
            </b-row>
            <b-row>
              <b-col cols="4">
                Open:
              </b-col>
              <b-col>
                {{ st.openTime + ' - ' + st.closeTime }}
              </b-col>                   
            </b-row>
            <b-row>
              <b-col cols="4">
                Address:
              </b-col>
              <b-col>
                {{ st.address }}&nbsp;
                <b-link :href="getPostCodeLink(st.postcode)">
                  {{ st.postcode }}
                </b-link>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="float-right">
            <b-row>&nbsp;</b-row>
            <b-row>&nbsp;&nbsp;&nbsp;<div class="float-right">
              <feather-icon
                id="notifications"
                icon="ChevronRightIcon"
                size="24"
                @click="GoToAssignment(st.assignmentIdIndex)"
              />
            </div></b-row>
            <b-row>&nbsp;</b-row>
          </b-col>           
        </b-row>
      </b-card>
    </div>
    <ProjectsButton :is-map="false" />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BLink,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ProjectsButton from '@/components/ui/ProjectsButton.vue'
import store from '@/store'
import router from '@/router'

export default {
  name: 'Assignments',
  components: {
    ProjectsButton,
    BRow,
    BCol,
    BCard,
    BButton,
    BLink,
  },
  computed: {
    ...mapGetters({ sitesList: 'app/getSiteDetailsList' }),
    ...mapGetters({ assignmentsIDList: 'app/getUserAssignmentsIDList' }),
  },
  methods: {
    getPostCodeLink(postCode) {
      return `https://maps.google.com/maps?q=${postCode}`
    },
    getPhoneLink(phoneNo) {
      return `tel:${phoneNo}`
    },
    GoToAssignment(assignmentIdIndex) {
      store.dispatch('app/getCurrentJobForCurrentDay', this.assignmentsIDList[assignmentIdIndex])
      store.commit('app/changeUserAssignmentsID', this.assignmentsIDList[assignmentIdIndex])
    },
  },
}
</script>
