<template>
  <div class="oci-projects-button-container">
    <b-button
      type="submit"
      @click="getTomorrowJob"
    >
      Next job
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BFormInput,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { checkInOutMethods } from '@/components/ui/CheckInOutFunctions'
import { messageBox } from '@/components/ui/MessageBox'
import { loginMethods } from '@/auth/rpc/ociLoginFunctions'
import swal from 'sweetalert2'
import store from '@/store'

export default {
  name: 'JobForTomorrowButton',
  components: {
    BButton,
    BFormInput,
  },
  props: {
    isMap: {
      type: Boolean,
      required: true,
    },
    longitude: {
      type: Number,
      required: false,
    },
    latitude: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapGetters({ checkedInStatus: 'app/getCheckedInStatus' }),
    ...mapGetters({ operativeSiteId: 'app/getCurrentSiteOperativeId' }),
    ...mapGetters({ site: 'app/getCurrentSite' }),
  },
  methods: {
    getTomorrowJob() {
      // store.commit('app/setGetNextJob', true)
      // this.$router.push('/');
    },
  },
}
</script>
