<template>
  <div class="container oci-check-in-out-body">
    <div
      v-if="isLoadingAssigments"
      :class="salutationCss"
    >
      <div class="float-right">
        <feather-icon
          id="notifications"
          :icon=this.icon
          size="24"
          :color=this.color
          @click="Refresh"
        />
      </div>     
      <h2>Hello {{ userName }}</h2>
      <h5>Checking for new assignments...</h5>
      <div class="oci-check-in-no-jobs-img-section">
        <b-img
          :src="noJobsImg"
          alt="no jobs to do"
        />
      </div>
    </div>  
    <div
      v-else-if="checkedInStatus=='out' || checkedInStatus=='No assignments'" class="oci-map-header-nj"      
    >      
      {{ currentDay }}
        <div class="float-right">
          <feather-icon
            id="notifications"
            :icon=this.icon
            size="24"
            :color=this.color
            @click="Refresh"
          />
        </div>   
      <div :class="salutationCss">    
      <h2 >Hello {{ userName }}</h2>
      <h5>You haven't got any projects today</h5>
      <div class="oci-check-in-no-jobs-img-section">
        <b-img
          :src="noJobsImg"
          alt="no jobs to do"
        />
      </div>    
      </div> 

      <div class="oci-projects-button-container">
        <b-button
          type="submit"
          @click="getFutureAssignment"
        >
          Next job
        </b-button>
      </div>      

    </div>
    <div v-else>
      <div class="oci-check-in-out-heading-date">

      <div
        v-if="this.isFromProjectListPage"
      >
        <div v-if="((this.totalAssignments >= 1 && this.checkedInStatus !== 'in' && this.checkedInStatus !== 'out') || this.isFromProjectListPage)" class="oci-map-header">
          <div class="float-left">
            <feather-icon
              id="notifications"
              icon="ChevronLeftIcon"
              size="24"
              @click="GoToBackAssignmentListPage"
            />
          </div>
        </div>        
        {{formatDate(site.date)}}        
      </div>
      <div v-else>      
        <div v-if="((this.totalAssignments >= 1 && this.checkedInStatus !== 'in' && this.checkedInStatus !== 'out' && this.userAssignmentSet) || this.isFromProjectListPage)" class="oci-map-header">
          <div class="float-left">
            <feather-icon
              id="notifications"
              icon="ChevronLeftIcon"
              size="24"
              @click="GoToBackAssignmentListPage"
            />
          </div>
        </div>  
         
      {{ formatDate(site.date) }}
        <div class="float-right">
          <feather-icon
            id="notifications"
            :icon=this.icon
            size="24"
            :color=this.color
            @click="Refresh"
          />
        </div>      
      </div>

      </div>

      <div :class="salutationCss">
        <h2 class="mb-1">
          Hello {{ userName }}
        </h2>
        <h5>{{ subHeading }}</h5>
      </div>
      <div
        v-if="totalAssignments <= 1 || this.userAssignmentSet || this.oneAlreadyCheckedIn"
      >
        <CheckInOut />
      </div>
      <div v-else>  
        <Assignments /> 
      </div>
      <!-- <CheckInOut
        v-for="data in (jsonData)"
        :key="data.job"
        :projectData="data"
      /> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BButton, BImg } from 'bootstrap-vue'
import CheckInOut from '@/views/components/CheckInOut.vue'
import Assignments from '@/views/components/Assignments.vue'
import ProjectsButton from '@/components/ui/ProjectsButton.vue'
import JobForTomorrowButton from '@/components/ui/JobForTomorrowButton.vue'
import { checkInOutMethods } from '@/components/ui/CheckInOutFunctions'
import store from '@/store'
import { loginMethods } from '@/auth/rpc/ociLoginFunctions'

export default {
  components: {
    ProjectsButton,
    JobForTomorrowButton,
    Assignments,
    CheckInOut,
    BImg,
    BButton,
  },
  computed: {
    ...mapGetters({ getNextJob: 'app/getGetNextJob' }),  
    ...mapGetters({ site: 'app/getCurrentSiteDetails' }),
    ...mapGetters({ isLoadingAssigments: 'app/getIsLoadingAssigments' }),
    ...mapGetters({ totalAssignments: 'app/getTotalAssignments' }),
    ...mapGetters({ checkedInStatus: 'app/getCheckedInStatus' }),
    ...mapGetters({ userName: 'app/getUserName' }),
    ...mapGetters({ currentDay: 'app/getCurrentDateHeading' }),
    ...mapGetters({ assignmentID: 'app/getUserAssignmentsID' }),
    ...mapGetters({ assignmentsIDList: 'app/getUserAssignmentsIDList' }),
    ...mapGetters({ signintime: 'app/getSignInTime' }),
    ...mapGetters({ userAssignmentSet: 'app/getUserAssignmentSet' }),
    ...mapGetters({ oneAlreadyCheckedIn: 'app/getOneAlreadyCheckedIn' }),
    ...mapGetters({ sitesList: 'app/getSiteDetailsList' }),
    ...mapGetters({ previousAssignmentsID: 'app/getPreviousAssignmentsID' }),
    ...mapGetters({ isFromProjectListPage: 'app/getIsFromProjectListPage' }),     

    salutationCss() {
      // If checked in use check out style
      if (this.checkedInStatus === 'in') return 'oci-check-out-salutation'

      return 'oci-check-in-salutation'
    },
    subHeading() {
      if (this.checkedInStatus === 'in') return 'You have signed in at '+this.signintime
      if (this.totalAssignments > 1) {
        return 'Your accepted projects:'
      }else{
        return 'Your accepted project:'  
      }
    },
  },
  data() {
    return {
      icon: "RefreshCwIcon",
      color: "#6E6B7B",
      jsonData: [],
      noJobsImg: require('@/assets/images/pages/rafiki.png'),
    }
  },
  created() {
    console.log('getNextJob', this.getNextJob)
    store.commit('app/setGetNextJob', false)
    this.LoadData()
    console.log('getNextJob', this.getNextJob)
  },
  methods: {
    Refresh() {
      // if (this.icon === "RefreshCwIcon")
      //  this.icon = "RefreshCcwIcon"
      // else  
      //  this.icon = "RefreshCwIcon"

      this.color = "#49A7EA"
      store.commit('app/setGetNextJob', false)
      store.commit('app/setIsFromProjectListPage', false)
      this.LoadData()  

      setTimeout(() => this.color = "#6E6B7B", 250); // not ideal, but for now!!!
    },   
    enableLogin() {
      this.loginDisabled = false
    },
    async doLogin() {
      this.loginDisabled = true
      await loginMethods.Login(loginMethods.loginUser, loginMethods.loginPw, this.enableLogin)
        .then(result => {
          console.log(result)
          this.loginDisabled = false
        })
        .catch(error => {
          console.log(error)
          this.loginDisabled = false
        })
    },     
    LoadData() {
      console.log('LoadData1')
      store.commit('app/setGetNextJob', false)
      this.getCurrentAssignment()      
      console.log('LoadData2')
      /*
      console.log('LoadData1')
      if (this.isFromProjectListPage) {
        console.log('LoadData2')
        const todayDate = new Date()
        checkInOutMethods.getTodaysJobs(todayDate, this.displayScreen)
        this.displayScreen()   
      }else{
        console.log('LoadData3')
        const todayDate = new Date()
        checkInOutMethods.getTodaysJobs(todayDate, this.displayScreen)
        this.$store.dispatch('app/getNewNotificationList') // put this in a timer
      }   
      */
    },    
    formatDate(data) {
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];      
      return String(data.day) +' '+ monthNames[data.month-1]+' '+ String(data.year)      
    },     
    displayScreen() {
      if (this.totalAssignments <= 1 || this.userAssignmentSet) {
        if (this.assignmentID > 0){
          this.$store.dispatch('app/getCurrentJobForCurrentDay', this.assignmentID)
        }
        return
      }
      if (this.totalAssignments > 1) {  
        this.$store.dispatch('app/getJobsListForCurrentDay', this.assignmentsIDList)
        for (let i=0; i<this.sitesList.length; ++i){
          if (this.sitesList[i].checkedInStatus == 'in' || this.sitesList[i].checkedInStatus == 'out') {
            this.$store.dispatch('app/getCurrentJobForCurrentDay', this.assignmentsIDList[this.sitesList[i].assignmentIdIndex])
            return
          }
        }   
      }      
    },
    getCurrentAssignment() {
      this.$store.dispatch('app/getCurrentAssignment')    
    },    
    getFutureAssignment() {
      store.commit('app/setGetNextJob', true)
      this.$store.dispatch('app/getFutureAssignment')    
    },     
    GoToBackAssignmentListPage() {
      store.commit('app/setIsCheckInOut', true);
      if (this.isFromProjectListPage){
        store.commit('app/changeUserAssignmentsID', this.previousAssignmentsID)
        store.commit('app/setIsFromProjectListPage', false);
        this.$router.go(-1)
        return
      }
      store.commit('app/setIsFromProjectListPage', false);
      store.commit('app/setUserAssignmentSet', false)      
      // this.$router.go(-1)
    },    
  },
}
</script>
