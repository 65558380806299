<template>
  <div class="oci-projects-button-container">
    <b-button
      type="submit"
      @click="goToProjectsList"
    >
      &nbsp;&nbsp;&nbsp;&nbsp;Your projects&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { messageBox } from '@/components/ui/MessageBox'
import { loginMethods } from '@/auth/rpc/ociLoginFunctions'
import swal from 'sweetalert2'

export default {
  name: 'ProjectsButton',
  components: {
    BButton,
  },
  props: {
    isMap: {
      type: Boolean,
      required: true,
    },
    longitude: {
      type: Number,
      required: false,
    },
    latitude: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapGetters({ site: 'app/getCurrentSite' }),
  },
  methods: {
    showWarningMessage(msgDescription, msgTitle) {
      swal.fire({
        title: msgTitle,
        text: msgDescription,
        icon: 'warning',
        confirmButtonText: 'Close X',
        customClass: {
          confirmButton: 'btn oci-close-button',
        },        
        buttonsStyling: false,
      })
    },  
    goToProjectsList() {
      this.$router.push('/projects')
    },
  },
}
</script>
